document.addEventListener('visibilitychange', () => {
  const hasConsentCookie = document.cookie
    .split(';')
    .some(item => item.trim().startsWith('sendity-com_consent='));
  const hasReloaded = localStorage.getItem('consentReloaded');

  if (hasConsentCookie && !hasReloaded) {
    localStorage.setItem('consentReloaded', true);
    window.addEventListener('DOMContentLoaded', () => {
      window.location.reload();
    });

    // this works when user change tabs
    if (document.visibilityState === 'visible') {
      window.location.reload();
    }
  }
});
document.addEventListener('DOMContentLoaded', () => {
  const hasConsentCookie = document.cookie
    .split(';')
    .some(item => item.trim().startsWith('sendity-com_consent='));
  const hasReloaded = localStorage.getItem('consentReloaded');

  if (hasConsentCookie && !hasReloaded) {
    localStorage.setItem('consentReloaded', true);
    window.addEventListener('DOMContentLoaded', () => {
      window.location.reload();
    });

    // this works when user change tabs
    if (document.visibilityState === 'visible') {
      window.location.reload();
    }
  }
});
